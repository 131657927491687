import React from "react"
import Img from "gatsby-image"
import { BaseCard } from "./components.css"

function Card({ content, background, imgData }) {
  return (
    <BaseCard>
      <Img
        fluid={imgData.childImageSharp.fluid}
        alt="I am some alt"
        style={{ width: `100%`, height: `100%` }}
      />
    </BaseCard>
  )
}

export default Card
