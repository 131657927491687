import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const BaseCard = styled.div`
  height: 350px;
  width: 360px;
  border-radius: 25px;
  box-shadow: var(--main-shadow);
  margin: var(--margin-med);
  overflow: hidden;
`

export const StyledProfileCard = styled.div`
  height: 350px;
  width: 355px;
  border-radius: 25px;
  box-shadow: var(--main-shadow);
  overflow: hidden;
`
export const StyledContactCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 280px;
  width: 425px;

  border-radius: 25px;
  box-shadow: var(--main-shadow);
  color: var(--white);
  padding: var(--padding-large);
  cursor: pointer;
  background: var(--primary-brown);
  h2 {
    margin-top: 0;
    margin-bottom: var(--margin-small);
    max-width: 140px;
  }
  @media (max-width: 1024px) {
    height: 215px;
    width: 225px !important;
  }
  @media (max-width: 1440px) {
      padding: var(--padding-med);

    height: 235px;
    width: 245px !important;
    h2 {
      margin-bottom: var(--margin-small);
    }
  }
  @media (max-width: 650px) {
     height: 200px;
     margin-bottom:var(--margin-small);
    width: 225px !important;
        h2 {
          font-size:var(  --heading-tertiary);
          margin-top: var(--margin-small);
        }
      }
  ${props =>
    props.primaryBlue &&
    css`
      background: var(--primary-blue);
      margin-left: var(--margin-med);
      @media (max-width: 650px) {
        margin-left: 0;
        margin-top: var(--margin-med) !important;
      }
    `}

  ${props =>
    props.showForm &&
    css`
      width: 100% !important;
      max-width: 700px;
      background: var(--primary-brown);
      height: auto;
      p {
        margin-bottom: 0;
      }
    `}
`
