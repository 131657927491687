import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import bgPath from "../../images/background_frame.svg"
import bgPathTop from "../../images/bg_top_right.svg"
import bgPathBottom from "../../images/bg_bottom_left.svg"
const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
  style: { height: `${dynamicHeight}px` },
}))`
  position: relative;
  width: 100%;
`

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  overflow-x: hidden;
`

const HorizontalTranslateContainer = styled.div`
  position: absolute;
  height: 100%;
  will-change: transform;
`

const calcDynamicHeight = objectWidth => {
  const vw = window.innerWidth
  const vh = window.innerHeight
  return objectWidth - vw + vh + 150
}

const handleDynamicHeight = (ref, setDynamicHeight) => {
  const objectWidth = ref.current.scrollWidth
  const dynamicHeight = calcDynamicHeight(objectWidth)
  setDynamicHeight(dynamicHeight)
}

const applyScrollListener = (ref, setTranslateX) => {
  window.addEventListener("scroll", () => {
    const offsetTop = -ref.current.offsetTop
    setTranslateX(offsetTop)
  })
}

export default ({ children }) => {
  const [dynamicHeight, setDynamicHeight] = useState(0)
  const [translateX, setTranslateX] = useState(0)

  const containerRef = useRef(null)
  const objectRef = useRef(null)

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight)
  }

  useEffect(() => {
    handleDynamicHeight(objectRef, setDynamicHeight)
    window.addEventListener("resize", resizeHandler)
    applyScrollListener(containerRef, setTranslateX)
  }, [])

  return (
    <>
      <TallOuterContainer dynamicHeight={dynamicHeight}>
        <StickyInnerContainer
          ref={containerRef}
          style={{
            background: `url(${bgPath})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `105%`,
            backgroundAttachment: `fixed`,
          }}
        >
          <HorizontalTranslateContainer
            style={{ transform: `translateX(${translateX}px)` }}
            ref={objectRef}
          >
            {children}
          </HorizontalTranslateContainer>
        </StickyInnerContainer>
      </TallOuterContainer>
    </>
  )
}
