import React from "react"
import Img from "gatsby-image"
import { StyledProfileCard } from "./components.css"

function ProfileCard({ content, imgData }) {
  return (
    <StyledProfileCard>
      <Img
        fluid={imgData.childImageSharp.fluid}
        alt="I am some alt"
        style={{ width: `100%`, height: `100%` }}
      />
    </StyledProfileCard>
  )
}

export default ProfileCard
