import React from "react"
import { motion, useMotionValue } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function ElephantFour() {
  const x = useMotionValue(0)
  const data = useStaticQuery(graphql`
    query ElephantFourImageQuery {
      imageSharp(fixed: { originalName: { eq: "Elephant4.png" } }) {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      style={{ x }}
      className="elephant"
    >
      <Img fluid={data.imageSharp.fluid} style={{ width: 750 }} />
    </motion.div>
  )
}
