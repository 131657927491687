import styled from "styled-components"
import { motion } from "framer-motion"
export const StyledProfile = styled.div`
  width: 100%;
  max-width: 400px;
  margin: var(--margin-med);
  cursor: pointer;
  position: relative;
  z-index: 8;
`
export const ProfileRow = styled.section`
  display: flex;
  justify-content: center;
  padding: var(--padding-large);
  width: 100vw;
`
export const StyledFullProfile = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  max-width: 1400px;
  height: 90vh;
  position: absolute;
  top: 5vh;
  z-index: 9;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--main-shadow);
  border: 1px solid var(--white-20);
  .full-text {
    padding: var(--padding-xl);
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  left: 25px;
  background: none;
  border: none;
  color: var(--black);
  cursor: pointer;
`
