import styled from "styled-components"

export const StyledFormContainer = styled.div`
  background: var(--primary-blue);
  box-shadow: var(--main-shadow);
  border-radius: var(--border-radius);
  padding: var(--padding-large);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white);
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  input {
    padding: 1rem;
    border: none;
    border-radius: var(--border-radius);
    background: var(--white);
    opacity: 1;
    width: 300px;
  }
  button {
    background: var(--green);
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    padding: 1rem;
    width: 300px;
    cursor: pointer;
    transition: var(--transition-hover);
    &:hover {
      color: #000;
      opacity: 0.9;
    }
  }
  span {
    background: var(--green);
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    padding: 1rem;
    width: 300px;
    display: block;
  }
`
