import React, { useState } from "react"
import ProfileCard from "../Card/ProfileCard"
import { StyledProfile } from "./components.css"
import FullProfile from "./FullProfile"

function Profile({ name, image, excerpt, fullProfile }) {
  const [isProfileVisible, setProfileVisbility] = useState(false)

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <>
      {isProfileVisible && (
        <FullProfile
          fluidImage={image}
          fullProfile={fullProfile}
          visibility={setProfileVisbility}
        />
      )}

      <StyledProfile onClick={() => setProfileVisbility(!isProfileVisible)}>
        {/* <FullProfile fluidImage={image} fullProfile={fullProfile} /> */}
        <ProfileCard imgData={image} />
        <div>
          <h3>{name}</h3>
          <p>{excerpt}</p>
        </div>
      </StyledProfile>
    </>
  )
}

export default Profile
