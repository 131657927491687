import React, { useState } from "react"
import { motion } from "framer-motion"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { StyledFormContainer } from "./components.css"

function SubscribeForm() {
  const [placeholder, setPlaceholder] = useState("Subscribe to Our Newsletter")
  const [inputValue, setInputValue] = useState("Subscribe to Our Newsletter")
  const [showSubmit, setShowSubmit] = useState(false)
  const [showInput, setShowInput] = useState(true)
  const [showThankYou, setShowThankYou] = useState(false)
  const [email, setEmail] = useState("")

  const focusHandler = () => {
    setPlaceholder(inputValue)
    setInputValue("")
    setShowSubmit(!showSubmit)
  }

  const submitHandler = () => {
    setShowInput(false)
    setShowSubmit(false)
    setShowThankYou(true)

    handleSubmit
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    console.log(email)
    console.log("submit")
    addToMailchimp(email)
      .then(data => {
        console.log(data.result)
        setShowInput(false)
        setShowSubmit(false)
        setShowThankYou(true)
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        // console.log(error)
      })
  }

  return (
    <StyledFormContainer>
      <h2 style={{ margin: `var(--margin-small)` }}>
        Get the latest from
        <br />
        Brown Lawyers
      </h2>
      <form onSubmit={handleSubmit} method="post">
        {showInput && (
          <p>
            <label htmlFor="email" style={{ opacity: 0, fontSize: 1 }}>
              Email
            </label>
            <input
              type="text"
              name="email"
              placeholder={placeholder}
              onFocus={focusHandler}
              onBlur={handleEmailChange}
            />
          </p>
        )}
        {showSubmit && <button type="submit">Submit</button>}
        {showThankYou && (
          <motion.span style={{ opacity: 0 }} animate={{ opacity: 1 }}>
            Thank You!
          </motion.span>
        )}
      </form>
    </StyledFormContainer>
  )
}

export default SubscribeForm
