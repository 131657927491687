import styled, { css } from "styled-components"
import { motion } from "framer-motion"
export const StyledGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: 122px 122px 122px 122px 122px 122px 122px 122px 122px 122px 122px;
  grid-column-gap: 16px;
  margin: var(--centre);
  /* margin-bottom: var(--margin-xl); */
  align-items: center;
  justify-content: center;
  width: 90%;
.brown-logo {
      left: -150px;
    }
  /* width: 1200px; */
  height: auto !important;
  .col-1 {
    grid-column-start: 2;
    grid-column-end: 7;
  }

  .col-2 {
    padding-left: 20px;
    grid-column-start: 7;
    grid-column-end: 13;
    max-width: 450px;
  }
  @media (max-width: 1440px) {
    grid-template-columns: 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3%;
    grid-column-gap: 10px;
    width: 95%;
    margin: 0 auto;
    
  }
  /* @media (min-width: 1024px) {
    height: 100% !important;
    max-height: 100vh;
  } */

  
  @media (max-width: 425px) {
    padding-left: 0;
    img {
      width: 225px !important;
      left: 0 ;
    }
  }
  @media screen and (max-width: 1024px) {
    .gatsby-wrapper {
      max-width: 430px !important;
      height: auto !important;
    }
    img {
      max-width: 430px !important;
      height: auto !important;
    }
    .elephant {
      position: relative;
      left: -10%;
      top: 12.5%;
      display: flex;
      align-items: center;
    }
    .col-1 {
      grid-column-start: 2;
    grid-column-end: 6;
      margin:0!important;
    }
    .col-2 {
grid-column-start: 6;
    grid-column-end: 12;
    .secondary-menu {
      max-width: 400px;
    }
    .brown-logo {
      height: 120px !important;
      left: -100px;
    }
    p{
      max-width:400px;
    }
    }
  }
  @media (max-width: 768px) {
    .col-2 {
      grid-column-start: 2;
      grid-column-end: 13;
      padding-left: var(--padding-xl);
      max-width: 600px;
      p {
      line-height: 26px;
    }
    img {
      width: 225px !important;
      left:0 !important;
    }
    grid-template-columns: 12fr;
    grid-template-rows: auto;
    div {
      max-width: 350px !important;
      /* margin: auto !important; */
      /* margin-top: 0 !important; */
    }
    }
    .gatsby-image-wrapper {
      display: none !important;
    }
  nav {
        margin: 0 auto !important;
      }
}
 @media (max-width: 425px) {
       grid-template-columns: 1fr;

   .col-2 {
      grid-column-start: 1;
      grid-column-end: 13;
      padding:var(--padding-med);
      p {
        max-width:400px;
      }
      
   }
   }
`
export const StyledGridBigContent = styled(motion.div)`
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 122px 122px 122px 122px 122px 122px 122px 122px 122px 122px 122px;
  
  grid-column-gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: var(--centre);

  
  @media (max-width: 1024px) {
    grid-column-gap: 5px;
  }
  @media (max-width: 768px) {
     width:90%;
  }

  .col-1 {
    grid-column-start:1;
    grid-column-end:4;
    align-items: center;
    .elephant {
      position: relative;
      left: -33%;
    }
    @media (max-width: 1024px) {
      grid-column-start:2;
      grid-column-end:6;
      .gatsby-image-wrapper {
        max-width:330px;
        height: auto !important;
      }
      img {
        max-width: 330px ;
        height: auto !important;
      }
      .elephant {
      position: relative;
      left: -20% ;
      top:12.5%;
      display:flex;
      align-items:center;
    }
    }
    @media (max-width:768px) {
      display:none !important;
    }
  }

  .col-2 {
    padding-left: 20px;
    grid-column-start:5;
    grid-column-end:13;
    img {
      margin-top: 100px;
    }
    .brown-logo {
        /* height: 100px !important; */
        @media (max-width: 1440px) { 
        left: -90px !important;
        }
        @media (max-width: 1024px) { 
        left: -80px !important;
        }
        @media (max-width: 650px) { 
        left: 0 !important;
        }
      }
    @media (max-width: 1440px) { 
      padding:0;
      padding-top: 20px;

      grid-column-start:6;
      /* .brown-logo {
        height: 100px !important;
        left: -90px !important;
      } */
    }
    @media (max-width:1024px) {
      /* .brown-logo {
        height: 90px !important;
        left: -80px !important;
      } */
    }
    @media (max-width:768px) {
      grid-column-start:3 ;
    grid-column-end:13;
    /* padding-left:var(--padding-xl); */
    img {
      margin-top:0;
    }
    }
  }
  @media  (max-width: 768px) {
  & > &:first-child {
    width: 100%;
  }  
    .gatsby-image-wrapper {
      display: none;
    }
    img {
      margin-top: 50px;
      width: 225px !important;
      left: -11% !important;
      height: auto !important;
    }
    grid-template-columns: 12fr;
    grid-template-rows: auto;
    div {
      /* max-width: 350px !important; */
      /* margin: auto !important; */
      margin-top: 0 !important;
    }
  }
  @media (max-width:650px) {
    .col-2 {
      grid-column-start:1 ;
      margin:auto;
    }
    .card-section {
      flex-direction: column;
      width:auto;
    }
    
  }
  @media (max-width: 1440px) {
    grid-template-columns: 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3% 8.3%;
    grid-column-gap: 10px;
    width:95%;
    max-width:1368px;
    margin: 0 auto;
    .col-1 {
      .elephant {
        top: 10%;
              left: 0;

      }
    .gatsby-image-wrapper {
        max-width:450px;
        height: auto !important;
      }
      img {
        max-width: 450px ;
        height: auto !important;
      }
  }
}
  
@media (max-width:1024px) {
  .col-1 {
      .elephant {
        top: 10%;
              left: -10%;

      }
    .gatsby-image-wrapper {
        max-width:450px;
        height: auto !important;
      }
      img {
        max-width: 450px ;
        height: auto !important;
      }
  }
}

`
export const ScrollDiv = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  scroll-snap-align: start;
  /* padding: var(--padding-med); */
  /* margin: 0 var(--margin-small); */

  & > div:first-of-type {
    /* width: 50%; */
  }
`

export const HorizontalSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

export const BumperSection = styled.section`
  text-align: center;
  padding: 0px;
  background-color: #efefef;
`

export const CardsContainer = styled.div`
  position: relative;
  height: 100%;
  max-height: 100vh;
  /* padding: 0 0 0 150px; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  ${props =>
    props.leftPadding &&
    css`
      padding-left: 400px;
    `}
`

export const BlogDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 0 var(--margin-small);
  padding: var(--padding-med);
  width: 100%;
  min-width: 600px;
  max-width: 1000px;
  height: 500px;
  border-radius: var(--border-radius);
  box-shadow: var(--main-shadow);
  background: var(--white);
  border: 1px solid var(--white-60);
  ${props =>
    props.fullBlog &&
    css`
      height: 100%;
      max-height: 800px;
      overflow-y: auto;
      margin: 5% auto 0;
    `}
`
