import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { motion, useMotionValue } from "framer-motion"
import Img from "gatsby-image"

export default function ElephantBack2() {
  const data = useStaticQuery(graphql`
    query ElephantBack2ImageQuery {
      imageSharp(fixed: { originalName: { eq: "ElephantBack2.png" } }) {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const x = useMotionValue(0)
  return (
    <motion.div
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      style={{ x }}
      className="elephant"
    >
      <Img
        fluid={data.imageSharp.fluid}
        style={{ width: 600, margin: `0 auto` }}
      />
    </motion.div>
  )
}
