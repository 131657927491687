import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MenuContext } from "../context/MenuContext"
import MainMenu from "../components/Menu/MainMenu"
import Layout from "../components/Layout"
import { ScrollDiv, StyledGrid } from "../components/Layout/components.css"
import Card from "../components/Card"
import Profile from "../components/Profile/"
import { ProfileRow } from "../components/Profile/components.css"
import SubscribeForm from "../components/Subscribe"
import ElephantFour from "../components/Elephant"
import ElephantFront2 from "../components/Elephant/ElephantFront2"
import ElephantBack2 from "../components/Elephant/ElephantBack2"

import HorizontalScroll from "../components/HorizontalScroll/"
import brownLogo from "../images/BrownLaw_Logo_Dark.svg"

import { motion, useCycle } from "framer-motion"
import PageDiv from "../components/PageDiv"
import "../components/Layout/slider.css"

function WhatWeDo2({ data }) {
  const [animate, toggleFocus] = useCycle(
    {
      container: { height: "100%", top: "0rem", overflowX: "auto" },
      title: { opacity: 1 },
    },
    {
      container: { height: "100%", top: "-4.4rem", overflowX: "hidden" },
      title: { opacity: 0 },
    }
  )
  const { isMenuOpen } = useContext(MenuContext)
  const variants = {
    open: { x: "-10%", opacity: 1 },
    closed: { x: 0, opacity: 0.5 },
  }
  const menuElephantVariants = {
    open: { x: 0 },
    closed: { x: "-50%" },
  }
  const logoVariants = {
    open: { x: 0 },
    closed: { x: `25%` },
  }
  const pageLoad = {
    open: { x: "-10%", y: "10%", opacity: 0.5 },
    closed: { x: 0, y: 0, opacity: 1 },
  }
  const elephantLoad = {
    open: { x: "-50%" },
    closed: { x: 0 },
  }

  return (
    <Layout>
      <motion.div
        className="card-container"
        animate={animate.container}
        initial={{ top: "0rem" }}
        transition={{ ease: "easeOut", delay: 0.1 }}
      >
        <motion.div className="card-wrapper">
          <div className="card">
            <figure className="card-image-container">
              <div className="card-image-wrapper">
                <motion.div className="card-image">
                  {isMenuOpen && (
                    <StyledGrid
                      initial="closed"
                      animate="open"
                      variants={variants}
                    >
                      <motion.div
                        style={{ marginLeft: `auto`, marginRight: `125px` }}
                        variants={menuElephantVariants}
                      >
                        <ElephantFront2 />
                      </motion.div>
                      <div style={{ maxWidth: `500px` }}>
                        <motion.img
                          src={brownLogo}
                          alt="Brown Logo"
                          style={{
                            height: 125,
                            width: `auto`,
                            position: `relative`,
                            left: `-105px`,
                          }}
                          variants={logoVariants}
                        />
                        <MainMenu />
                      </div>
                    </StyledGrid>
                  )}
                  {!isMenuOpen && (
                    <StyledGrid
                      initial="open"
                      animate="closed"
                      variants={pageLoad}
                    >
                      <motion.div variants={elephantLoad} className="col-1">
                        <ElephantBack2 />
                      </motion.div>

                      <div className="col-2">
                        <h1>
                          <span className="h2">Preventive and proactive </span>
                          <br />
                          Our Approach to Law
                        </h1>
                        <p>
                          Brown Lawyers believes in the value of taking a
                          preventive and proactive approach to making law work
                          for you. Our goal is to provide legal harmony for our
                          clients. Let’s anticipate the things that are likely
                          to happen in your life and create a plan for the best
                          possible outcome.
                        </p>
                      </div>
                    </StyledGrid>
                  )}
                </motion.div>
              </div>
            </figure>
          </div>
        </motion.div>
        <motion.div className="card-wrapper">
          <div className="card">
            <figure className="card-image-container">
              <div className="card-image-wrapper">
                <motion.div className="card-image">
                  <StyledGrid>
                    <div className="col-1">
                      <Card imgData={data.card1} />
                    </div>
                    <div className="col-2">
                      <h2>Wills & Power of Attorney</h2>
                      <p>
                        Brown Lawyers believes in the value of taking a
                        preventive and proactive approach to making law work for
                        you. Our goal is to provide legal harmony for our
                        clients. Let’s anticipate the things that are likely to
                        happen in your life and create a plan for the best
                        possible outcome.{" "}
                      </p>
                    </div>
                  </StyledGrid>
                </motion.div>
              </div>
            </figure>
          </div>
        </motion.div>
        <motion.div className="card-wrapper">
          <div className="card">
            <figure className="card-image-container">
              <div className="card-image-wrapper">
                <motion.div className="card-image">
                  <StyledGrid>
                    <div className="col-1">
                      <h2>Corporate and Small Business</h2>
                      <p>
                        Brown Lawyers believes in the value of taking a
                        preventive and proactive approach to making law work for
                        you. Our goal is to provide legal harmony for our
                        clients. Let’s anticipate the things that are likely to
                        happen in your life and create a plan for the best
                        possible outcome.{" "}
                      </p>
                    </div>
                    <div className="col-2">
                      <Card imgData={data.card2} />
                    </div>
                  </StyledGrid>
                </motion.div>
              </div>
            </figure>
          </div>
        </motion.div>
        <motion.div className="card-wrapper">
          <div className="card">
            <figure className="card-image-container">
              <div className="card-image-wrapper">
                <motion.div className="card-image">
                  <StyledGrid>
                    <div className="col-1">
                      <Card imgData={data.card3} />
                    </div>
                    <div className="col-2">
                      <h2>Executor Services & Estates</h2>
                      <p>
                        Brown Lawyers believes in the value of taking a
                        preventive and proactive approach to making law work for
                        you. Our goal is to provide legal harmony for our
                        clients. Let’s anticipate the things that are likely to
                        happen in your life and create a plan for the best
                        possible outcome.
                      </p>
                    </div>
                  </StyledGrid>
                </motion.div>
              </div>
            </figure>
          </div>
        </motion.div>

        <motion.div className="card-wrapper">
          <div className="card">
            <figure className="card-image-container">
              <div className="card-image-wrapper">
                <motion.div className="card-image">
                  <StyledGrid>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 13 }}>
                      <SubscribeForm />
                    </div>
                  </StyledGrid>
                </motion.div>
              </div>
            </figure>
          </div>
        </motion.div>
      </motion.div>
    </Layout>
  )
}

export default WhatWeDo2

export const data = graphql`
  query WWDCardImages2 {
    card1: file(relativePath: { eq: "images/card1-placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          src
          srcSet
          sizes
        }
      }
    }
    card2: file(relativePath: { eq: "images/card2-placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          src
          srcSet
          sizes
        }
      }
    }
    card3: file(relativePath: { eq: "images/card3-placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          src
          srcSet
          sizes
        }
      }
    }
  }
`
