import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { motion, useMotionValue } from "framer-motion"
import Img from "gatsby-image"

export default function ElephantFront2() {
  const [isDragging, setDragging] = useState(false)
  const data = useStaticQuery(graphql`
    query ElephantFront2ImageQuery {
      imageSharp(fixed: { originalName: { eq: "ElephantFront2.png" } }) {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const x = useMotionValue(0)
  const variants = {
    visible: { transform: `rotate(0)` },
    hidden: { transform: `rotate(45deg)` },
  }
  return (
    <motion.div
      // drag
      // dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      // style={{ x }}
      // initial="hidden"
      // animate="visible"
      // variants={variants}

      className="elephant"
    >
      <Img
        fluid={data.imageSharp.fluid}
        style={{ width: 630, margin: `0 auto` }}
      />
    </motion.div>
  )
}
